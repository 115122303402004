import { ModalConfirmDeleteEmployee } from "@/components/modals/setting/modalConfirmDeleteEmployee";
import { ModalConfirmRecoverEmployee } from "@/components/modals/setting/modalConfirmRecoverEmployee";
import { ModalConfirmRetireEmployee } from "@/components/modals/setting/modalConfirmRetireEmployee";
import { HookContext, TContextHook } from "@/context/hook";
import { IModalCloseParams } from "@/models/Modal";
import { socket } from "@/pages";
import { setModal } from "@/store/global";
import dynamic from "next/dynamic";
import React from "react";
import { ModalSuccess } from "./success";

interface IProps {
  imgAssets: any;
  [key: string]: any;
}
export const AllModals: React.FC<IProps> = ({ ...props }) => {
  const hooks = React.useContext(HookContext) as TContextHook;
  const { route } = hooks.router
  const [isOpenModal, setIsOpenModal] = React.useState(false);
  const [reloadPage, setReloadPage] = React.useState(false);
  const routerReload = [
    "/statistic/staffPoint",
    "/statistic/staffPoint/[id]",
    "/statistic/department",
  ];
  const isCheckModal = typeof window === "object" && localStorage.getItem("isCheckModal");

  /**
   * Handle close modal and reload page
   */
  const onCloseModal = () => {
    if(routerReload.includes(route)) {
      setIsOpenModal(false);
      setReloadPage(true);
      localStorage.removeItem("isCheckModal");
    }
    setIsOpenModal(false);
  }

  // Handle get UUID local storage
  const getUUID = async () => {
    return new Promise((resolve) => {
      const value = localStorage.getItem("uuid");
      resolve(value);
    });
  };

  /**
   * Handle show modal when update point success anywhere in the system
   */
  socket.on("update_point_success", async (data) => {
    const uuid = await getUUID();
    if (uuid === data?.uuid) {
      setIsOpenModal(true);
    }
  })

  const handleModalClose = (modalCloseParams: IModalCloseParams) => {
    const modalId = modalCloseParams.modalId;
    const isCloseAll = modalCloseParams.isCloseAll;
    if(!isCloseAll){
      hooks.dispatch(setModal({
        modalId,
        isCloseAll,
        isShow: false
      }))
    }
  }
  return (
    <>
      <ModalConfirmDeleteEmployee
        handleModalClose={handleModalClose}
        {...props}
      />
      <ModalConfirmRetireEmployee
        handleModalClose={handleModalClose}
        {...props}
      />
      <ModalConfirmRecoverEmployee
        handleModalClose={handleModalClose}
        {...props}
      />
      {/* Modal update point success */}
      <ModalSuccess
        isShow={isOpenModal}
        onClose={onCloseModal}
        title={
          isCheckModal === "/statistic/department"
            ? "部署別実績の更新"
            : isCheckModal === "/statistic/staffPoint/[id]"
            ? "個人実績詳細の更新"
            : "個人別実績の更新"
        }
        textBtnClose="確認"
        content={
          isCheckModal === "/statistic/department"
            ? "部署別実績の更新が完了しました。"
            : isCheckModal === "/statistic/staffPoint/[id]"
            ? "個人実績詳細の更新が完了しました。"
            : "個人別実績の更新が完了しました。"
        }
        reloadPage={reloadPage}
      />
    </>
  );
};
