import { HookContext, TContextHook } from "@/context/hook";
import { container } from "@/di";
import React from "react";
import { useRouter } from "next/router";
import { setLoadData } from "@/store/setting";
import { SettingLabViewModel } from "@/modelviews/labo/setting";
import { handleHalfYear } from "@/helpers/stastistic";
import { setLoading } from "@/store/global";
import dayjs from "dayjs";
import { getFromTo, getTWFromTo } from "@/helpers/form";
import moment from "moment";


export const useSetting = ( typeTime: 'month' | 'year' | 'half_year' | 'day' | any   = 'month', queryDate: any = null, isAllTimeType : any = null ) => {

  const hooks = React.useContext(HookContext) as TContextHook;

  const { pathname } = useRouter() 
  

  let [isReadyUseSetting, setIsReadyUseSetting]: any = hooks.useState(false);
  const settingLabViewModel = container.resolve<SettingLabViewModel>( SettingLabViewModel );
        settingLabViewModel.setHooks(hooks);

  let _now = dayjs( )
  
  let betweenTime: any

  let  monthHalfYear: any

  interface Moment { [key: string]: any }

  const defaultInt: any = { dateTime: dayjs(), shiftTpye: 'end_of_month', typeTime,  dateBetween: { from: dayjs(), to: dayjs() }  }

  const settingLab: { dateTime: Moment, 
                      dateBetween: { from: any, to: any },
                      shiftTpye:any, first_half_from?: any, 
                      first_half_to?: any, second_half_from?: any, 
                      second_half_to?: any, typeTime?: any,
                    } 
                    = hooks.useSelector( (state:any) => state?.setting?.lab?.items ) || defaultInt

  
  const  [ intFeatch , setIntFeatch ]: any = React.useState <boolean> ( (Object.keys( settingLab ).length < 7) || false )                  
  
 
  const getShiftToDate = (shiftTpye: any, now:any = dayjs( ), flag : Boolean = true, dateValue: any = {} ) => {
    
    now = dayjs( now ).startOf( "day" )
    
    // if( dayjs().format("YYYY") != now.format("YYYY") )
    //  return now
    
    //  dayjs( new Date(`${ now.format("YYYY-MM") }/01/01`) )
    
    if(typeTime == 'day')
      return now

    if(typeTime == 'half_year')
    {
      
      const firstHalFrom = dayjs( new Date( `${dateValue?.year}/${dateValue?.first_half_from}/01` ) )
      const firstHalTo = firstHalFrom.add( 5, "month" )

      // ** get second time 

      const secondHalfFrom = dayjs( new Date( `${dateValue?.year}/${dateValue?.second_half_from}/01` ) )
      const secondHalTo = secondHalfFrom.add( 5, "month" )

      betweenTime = [
                      { from: firstHalFrom.startOf("month"), to: firstHalTo.endOf("month") }, 
                      { from: secondHalfFrom.startOf("month"), to: secondHalTo.endOf("month") },
                      //  ** subtract 1 year
                      { from: firstHalFrom.subtract( 1, "year" ).startOf("month"), to: firstHalTo.subtract( 1, "year" ).endOf("month") },
                      { from: secondHalfFrom.subtract( 1, "year" ).startOf("month"), to: secondHalTo.subtract( 1, "year" ).endOf("month") },
                      // ** add 1 year
                      { from: firstHalFrom.add( 1, "year" ).startOf("month"), to: firstHalTo.add( 1, "year" ).endOf("month") },
                      { from: secondHalfFrom.add( 1, "year" ).startOf("month"), to: secondHalTo.add( 1, "year" ).endOf("month") },
                    ]

      betweenTime = betweenTime.map( (time: any) => ({ ...getTWFromTo( time, shiftTpye ), formCR: time?.from }) )
      
      monthHalfYear = betweenTime.find( (val:any) => (val?.from.valueOf() <= now.valueOf() ) && (now.valueOf() <= val?.to.valueOf() ) )  

      // now = monthHalfYear?.formCR || dayjs()
      
      return ( monthHalfYear?.formCR || dayjs() )
    }

    switch (shiftTpye) {
      case "end_of_month":
          now = now.endOf('month'); break;
      default:          
          now = dayjs(`${now?.format("YYYY-MM")}-${shiftTpye}`); break;
    }
        
    
    if( flag && (dayjs().startOf( "day" ).valueOf() > dayjs(now).startOf( "day" ).valueOf()) )
      now = now.add( 1, "month" )
          
    return ( now || dayjs() )
  }

  const getShiftToDateHalfYear = (typeTime: any, shiftTpye: any, now:any = dayjs( ), flag : Boolean = true, dateValue: any = {} ) => {
    
    now = dayjs( now ).startOf( "day" )
    
    if(typeTime == 'day')
      return now

    if(typeTime == 'half_year')
    {
      
      const firstHalFrom = dayjs( new Date( `${dateValue?.year}/${dateValue?.first_half_from}/01` ) )
      const firstHalTo = firstHalFrom.add( 5, "month" )

      // ** get second time 

      const secondHalfFrom = dayjs( new Date( `${dateValue?.year}/${dateValue?.second_half_from}/01` ) )
      const secondHalTo = secondHalfFrom.add( 5, "month" )

      betweenTime = [
                      { from: firstHalFrom.startOf("month"), to: firstHalTo.endOf("month") }, 
                      { from: secondHalfFrom.startOf("month"), to: secondHalTo.endOf("month") },
                      //  ** subtract 1 year
                      { from: firstHalFrom.subtract( 1, "year" ).startOf("month"), to: firstHalTo.subtract( 1, "year" ).endOf("month") },
                      { from: secondHalfFrom.subtract( 1, "year" ).startOf("month"), to: secondHalTo.subtract( 1, "year" ).endOf("month") },
                      // ** add 1 year
                      { from: firstHalFrom.add( 1, "year" ).startOf("month"), to: firstHalTo.add( 1, "year" ).endOf("month") },
                      { from: secondHalfFrom.add( 1, "year" ).startOf("month"), to: secondHalTo.add( 1, "year" ).endOf("month") },
                    ]

      betweenTime = betweenTime.map( (time: any) => ({ ...getTWFromTo( time, shiftTpye ), formCR: time?.from }) )
      

      monthHalfYear = betweenTime.find( (val:any) => (val?.from.valueOf() <= now.valueOf() ) && (now.valueOf() <= val?.to.valueOf() ) )  

      // now = monthHalfYear?.formCR || dayjs()
      

      return ( monthHalfYear?.formCR || dayjs() )
    }

    switch (shiftTpye) {
      case "end_of_month":
          now = now.endOf('month'); break;
      default:          
          now = dayjs(`${now?.format("YYYY-MM")}-${shiftTpye}`); break;
    }
        
    
    if( flag && (dayjs().startOf( "day" ).valueOf() > dayjs(now).startOf( "day" ).valueOf()) )
      now = now.add( 1, "month" )

    return now
  }

  const getFromToFromDate = ( type: any, date: any, shiftTpye: any ) => {

    let res: any
    switch( type)
    {
      case "half_year":
        //return { from: date.subtract(1, 'month').add(1,"day"), to: date.add(5,"month") }        
        return handleHalfYear( { year: date?.year(), month: (date?.month()+1 ) , day: date?.date() }, shiftTpye, null )
      case "month":
          
          const dateBMonth = dayjs(`${(date.year())}-${(date.month()+1)}-01`)

          res = getShiftToDate(shiftTpye, dayjs(`${(date.year())}-${(date.month()+1)}-01`), false ) 

          if( shiftTpye == "end_of_month"  )
            return { from: dateBMonth, to: res }

        return { from: res.subtract(1, "month" ).add(1, "day" ), to: res }
      case "year":  
        
        const dateBYear = dayjs(`${Number(date.year())}-12-01`)

        res = getShiftToDate(shiftTpye, dateBYear, false ) 
        
        return { from: res.subtract(1, type as string ).add( 1, "day" ), to: res   }
      case "day":
        return { from: queryDate ? dayjs(queryDate) : date, to: queryDate ? dayjs(queryDate) : date }
      default:
        return { from: null, to: null }
    }

  }

  
  const getCurrentAccordingLogoutSetting = ( dateStogare:any,  item: any): { current_now:any, minTime:any, maxTime:any } => {  
    
    const { date_change_hour, date_change_minute } = item
    
    // get curret time
    let current_now = dateStogare ? dayjs(  dateStogare ) : dayjs()

    const { year, month, day } = { year: current_now?.year(), month: current_now?.month() + 1, day: current_now?.date() } 
    
    if( date_change_hour == undefined || date_change_minute == undefined )
      return ({ current_now: null, minTime: null, maxTime: null })
    
    let minTime: any = dayjs( new Date((`${year}/${month}/${day} ${date_change_hour}:${date_change_minute} GMT+0900`)) )
    let maxTime: any = dayjs( new Date((`${year}/${month}/${Number(day)} ${date_change_hour}:${date_change_minute} GMT+0900`)) ).add(1, "day")

    if( current_now.isBefore(minTime) )
    {
      current_now = current_now.subtract(1, "day")   
      minTime = minTime.subtract(1, "day")   
      maxTime = maxTime.subtract(1, "day")   
    }
      
        
    return ({ current_now, minTime, maxTime })
  }


  const mapDataStore = (data:any, fnc = getCurrentAccordingLogoutSetting ) => 
                        {
                          let timeNow: any = ""
                          
                          if( typeof window == "object" )
                          {
                            timeNow = localStorage.getItem( "dateStore" )
                            timeNow = timeNow ?  JSON.parse( timeNow ) : {}
                          }
                           
                          
                          const current_according_logout_setting : any =  fnc( timeNow[typeTime], data ) 

                          const dateTime:any = timeNow[typeTime] ? dayjs(  timeNow[typeTime] )
                                                                 : (
                                                                    typeTime == 'day' ? current_according_logout_setting?.current_now
                                                                                      : getShiftToDate( data.shift_deadline,  dayjs() , true, data )
                                                                    )
                                                                     

 
                          const shiftTpye = data?.shift_deadline || ""
                          const dateTimeHalfYear = getShiftToDateHalfYear( "half_year" , data.shift_deadline, dayjs(), true, data )  

                          return  ({ 
                              data: { ...data, 
                                      shiftTpye,
                                      dateTime,
                                      dateTimeWeek: moment(),
                                      dateBetween: getFromToFromDate(typeTime, dateTime, shiftTpye),
                                      dateHalfYear: getFromToFromDate("half_year", dateTimeHalfYear, shiftTpye),
                                      typeTime,
                                      ...current_according_logout_setting
                                    },   
                              type: "lab" 
                            })
                        }

  const featch = (year : any = new Date().getFullYear() ) => {
    
    settingLabViewModel.getSetting( { year } )
                       .then( (res: any) => {
                                                     
                          if(!res?.success)
                          {
                            return false
                          }

                          hooks.dispatch( setLoadData( mapDataStore(res.data ) ) )
                          setIsReadyUseSetting(true)
                        })
  }

  // destroy stroga when change page, reset int param filter for mode ( year, month, day, half-year )
  
  hooks.onRouteLeave( (e:any) => {

    if( ( (e.search("/statistic") < 0) && (e.search("/tasks") < 0) ) && ( typeof window == "object" ) )
      localStorage.setItem( "dateStore", "" )

  })

  React.useEffect( ()=>{
    
    featch()

    return () => {
      setIntFeatch(true)
    }

  }, [pathname] )

  React.useEffect( () => {  
    if (intFeatch) {
      featch();
      setIntFeatch(false);
    }

    return () => {
      // destroy when change page, reset int param filter for mode ( year, month, day, half-year )

      hooks.dispatch(setLoadData({ data: defaultInt, type: "lab" }));
      //setIntFeatch( true )
    };

  }, [intFeatch, queryDate, isAllTimeType] )

  // ** change time when type change
  React.useEffect( () => {     
                          
                          if( !intFeatch ) {
                            hooks.dispatch( setLoadData( mapDataStore(settingLab) ) )

                            // set init setting
                            if(Object.keys( settingLab ).length >= 7)
                            {
                              setIsReadyUseSetting(true)
                            }
                          }
                          
                          
                         }, [typeTime, intFeatch, queryDate, isAllTimeType] )

  return { settingLab, featch, getShiftToDate, isReadyUseSetting }

};
